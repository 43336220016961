import React, { useEffect, useState } from "react";
import { Card, CardBody, Col } from "reactstrap";
import CascadingApprovalSummaryTable from "./CascadingApprovalSummaryTable";
import axios from "axios";
import { isEmpty } from "@/shared/helpers";
import { connect } from "react-redux";

const CascadingApprovalIndex = ({ organization, program }) => {
  const [programs, setPrograms] = useState([]);
  const [defaultPrograms, setDefaultPrograms] = useState([]);
  useEffect(() => {
    const getData = async (organizationId, programId) => {
      if (isEmpty(programs)) {
        try {
          const programsApiUrl = `/organization/${organizationId}/program/${programId}/descendents?includeSelf=1&flat=1`;
          const response = await axios.get(programsApiUrl);
          if (response.data.length === 0) return { results: [], count: 0 };
          const data = response.data;
          // console.log(data);
          setPrograms(data);
          return data;
        } catch (e) {
          throw new Error(`API error:${e?.message}`);
        }
      }
    };
    if (organization?.id && program?.id) {
      getData(organization.id, program.id);
    }
  }, [programs, organization, program]);

  useEffect(() => {
    if (programs.length > 0) {
      const result = programs.map((x) => x.account_holder_id);
      setDefaultPrograms(result);
    }
  }, [programs]);

  if (isEmpty(defaultPrograms)) {
    return <p>Loading...</p>;
  }

  return (
    <Col md={12}>
      <Card>
        <CardBody>
          <CascadingApprovalSummaryTable programs={defaultPrograms} />
        </CardBody>
      </Card>
    </Col>
  );
};

const mapStateToProps = (state) => {
  return {
    organization: state.organization,
    program: state.program,
  };
};
export default connect(mapStateToProps)(CascadingApprovalIndex);
