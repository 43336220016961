import React from "react";
import { Row } from "reactstrap";
import ScheduleAwardSummaryCard from "./components/ScheduleAwardSummaryIndex.jsx";

const ScheduleAwardSummary = () => {
  return (
    <Row>
      <ScheduleAwardSummaryCard />
    </Row>
  );
};

export default ScheduleAwardSummary;
