import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Input, Col, Row, FormGroup, Label } from "reactstrap";
import { Form, Field } from "react-final-form";
import axios from "axios";
import { connect } from "react-redux";
import { useDispatch, flashSuccess, flash422 } from "@/shared/components/flash";
import {
  makeLabelizedOptionsFromTree,
  labelizeNamedData,
  unpatchSelect,
  isBadgeAward,
  isCustomAward
} from "@/shared/helpers";
import { getProgramTree } from "@/services/program/getProgramTree";
import TemplateButton from "@/shared/components/TemplateButton";
import getUnitNumbers from "@/services/program/getUnitNumbers";
import { getEvent } from "@/services/program/getEvent";
import { getBalance } from "@/services/program/getBalance";
import { getEvents } from "@/services/program/getEvents";
import EVENT_TYPES from "@/shared/json/eventTypes.json";
import formValidation from "@/validation/rewardNewUser";
import getAwardLevels from "@/services/program/getAwardLevels";

const DEFAULT_MSG_PARTICIPANT =
  "We wanted to thank you for all your extra efforts over the last couple of days.\n\nThough your response and tireless efforts. You made a BIG Different!!\n\nWe would like to recognize those efforts with this award to reflect our appreciation.\n\nGreg, Gerry and Bruce\n\nGreg and Gerry";

const AwardNewParticipant = ({ auth, organization, rootProgram }) => {
  const [programOptions, setProgramOptions] = useState([]);
  let [program, setProgram] = useState(null);
  const [loading, setLoading] = useState(false);
  const [inviting, setInviting] = useState(false);
  const [events, setEvents] = useState([]);
  const [event, setEvent] = useState(null);
  const [loadingEvent, setLoadingEvent] = useState(false);
  const [loadingPrograms, setLoadingPrograms] = useState(false);
  const [balance, setBalance] = useState(0);
  const [unitNumberOptions, setUnitNumberOptions] = useState(null);
  const [awardLevelOptions, setAwardLevelOptions] = useState([])

  const dispatch = useDispatch();

  useEffect(() => {
    if (organization && program) {
      getBalance(organization.id, program.value)
        .then((data) => {
          setBalance(data);
        })
        .catch((error) => {
          console.log(error.response.data);
        });
    }
  }, [organization, program]);

  useEffect(() => {
    setLoadingPrograms(true)
    if (rootProgram && rootProgram?.id) {
      getProgramTree(rootProgram.organization_id, rootProgram.id).then((p) => {
        setProgramOptions(makeLabelizedOptionsFromTree(p));
        setLoadingPrograms(false)
      });
    }
  }, [rootProgram]);

  useEffect(() => {
    let currentProgramId = program?.value ?? rootProgram?.id
    if( currentProgramId ) {
      getAwardLevels(organization.id, currentProgramId)
      .then( res => {
        setAwardLevelOptions(labelizeNamedData(res));
      })
    }
  }, [organization, rootProgram, program]);

  useEffect(() => {
    let currentProgramId = program?.value ?? rootProgram?.id
    if( currentProgramId ) {
      getUnitNumbers(organization.id, currentProgramId, "assignable=1")
      .then( res => {
        setUnitNumberOptions(labelizeNamedData(res));
      })
    }
  }, [organization, rootProgram, program]);

  useEffect(() => {
    if (!organization?.id || !program?.value) return;
    let mounted = true;
    setLoadingEvent(true);
    let except_type = [
      EVENT_TYPES.find((type) => type.name === "peer2peer allocation")?.value,
      EVENT_TYPES.find((type) => type.name === "peer2peer")?.value,
      EVENT_TYPES.find((type) => type.name === "peer2peer badge")?.value,
    ];
    getEvents(organization.id, program.value, { except_type: except_type }).then(
      (items) => {
        if (mounted) {
          // console.log(items)
          if (items.length > 0) {
            setEvents(labelizeNamedData(items));
            setEvent(items.shift());
          }
          setLoadingEvent(false);
        }
      }
    );
    return () => (mounted = false);
  }, [organization, program]);

  const onSelectProgram = (selectedOption) => {
    setProgram(selectedOption);
  };

  const onChangeProgramValue = ([fieldName, fieldVal], state, {changeValue }) => {
    changeValue(state, 'unit_number', () => null);
  }

  const onChangeEventValue = ([fieldName, fieldVal], state, {changeValue }) => {
    const field = state.fields["message"];
    field?.change(event.message);
  }

  const onChangeAwardValue = ([field], state, { setIn, changeValue }) => {
    const v = field.target.value;
    if (isNaN(v)) return;
    if (field.target.name === "override_cash_value") {
      if (parseInt(v) > event.max_awardable_amount) {
        alert(
          `Value cannot exceed max awardable amount ${event.max_awardable_amount}`
        );
        state.fields["override_cash_value"].change(event.max_awardable_amount);
        return;
      }
      const field = state.fields["awarding_points"];
      field?.change(program.factor_valuation * v);
    }
  };

  const onChangeEvent = (selectedOption) => {
    setLoadingEvent(true);
    getEvent(organization.id, program.value, selectedOption.value).then((item) => {
        setEvent(item);
        setLoadingEvent(false);
    });
  };

  const onSubmit = (values) => {
    setLoading(true);
    if( typeof values.unit_number === 'object') {
      values = unpatchSelect(values, ['unit_number'])
    }
    if( typeof values.award_level === 'object') {
      values = unpatchSelect(values, ['award_level'])
    }
    console.log("values", values)

    let formData = {
      first_name: values.first_name,
      last_name: values.last_name,
      email: values.email,
      unit_number: values.unit_number ? values.unit_number : null,
      award_level: values.award_level ? values.award_level : null
    };
    setInviting(true)
    axios
      .put(
        `/organization/${organization.id}/program/${values.program.value}/invite`,
        formData
      )
      .then((res) => {
        // console.log(res)
        setInviting(false)
        if (res.status == 200) {
          let newUser = res.data.user
          if(newUser){
            let formData = {
              user_id: [newUser.id],
              event_id: event.id,
              notes: values.notes ? values.notes : null,
              message: values.message,
              restrictions: values.restrictions ? values.restrictions : null,
              override_cash_value: values.override_cash_value
                ? values.override_cash_value
                : null,
              referrer: values.referrer ? values.referrer : null
            };
            if (values?.email_template_id && values.email_template_id?.value && !isNaN(values.email_template_id.value)) {
              formData["email_template_id"] = parseInt(values.email_template_id.value)
            }

            axios
            .post(
              `/organization/${organization.id}/program/${values.program.value}/award`,
              formData
            )
            .then((res) => {
              //   console.log(res)
              if (res.status === 200) {
                window.location.reload();
                flashSuccess(
                  dispatch,
                  "The User has been Invited to your program and Awarded successfully!"
                );
                setLoading(false);
              }
            })
            .catch((err) => {
              //console.log(error.response.data);
              flash422(dispatch, err.response.data);
              setLoading(false);
            });
          }
          
        }
      })
      .catch((error) => {
        //console.log(error.response.data);
        flash422(dispatch, error.response.data);
        setInviting(false)
        setLoading(false);
      });
  };

  let initialValues = {};
  if (event) {
    initialValues = {
      ...initialValues,
      ...{
        program: program,
        event_id: event? labelizeNamedData([event]): null,
        // event_type_id: event.event_type_id,
        // awarding_points: program.factor_valuation * event.max_awardable_amount,
        message: event.message ? event.message : DEFAULT_MSG_PARTICIPANT,
      },
    };
  }

  return (
    <div className="container">
      <Row>
        <Col md="6">
        <h5 className="text-center mb-3">Award New Participant</h5>
        </Col>
        <Col md="6">
        <h5 className="text-center mb-3">{`Funds Available for Reward: $${balance.toFixed(2)}`}</h5>
        </Col>
      </Row>

      <Form onSubmit={onSubmit} initialValues={initialValues} keepDirtyOnReinitialize
        validate={(values) => {
          return !values.pristine ? formValidation.validateForm(values) : {};
        }}
        mutators={{
          onChangeProgramValue,
          onChangeEventValue,
          onChangeAwardValue
        }}
      >
        {({ handleSubmit, form, submitting, pristine, values }) => (
          <form
            className="form d-flex flex-column justify-content-evenly"
            onSubmit={handleSubmit}
          >
            <div className="award-new-participant">
              {loadingPrograms && <span>{'Loading...'}</span>}
              {!loadingPrograms && programOptions.length > 0 && (
              <Row>
                <Col md="12">
                  
                  <Field
                    name={"program"}
                    parse={(value) => {
                      onSelectProgram(value);
                      return value;
                    }}
                  >
                    {({ input, meta }) => (
                      <div className="form__form-group">
                        {/* <span className="form__form-group-label">
                          Select your Reward Program
                        </span> */}
                        <div className="form__form-group-field">
                          <div className="form__form-group-row">
                            <Select
                              options={programOptions}
                              clearable={false}
                              className="react-select"
                              placeholder={" Select your Reward Program "}
                              classNamePrefix="react-select"
                              value={program}
                              // value={programOptions.find(option => option.value === input.value) || null} // Set selected value
                              // onChange={(selectedOption) => {
                              //   input.onChange(selectedOption ? selectedOption.value : null); // Update form state
                              //   onSelectProgram(selectedOption); // Update local state
                              // }}
                              onInputChange={form.mutators.onChangeProgramValue}
                              {...input}
                            />
                            {meta.touched && meta.error && (
                              <span className="form-error">{meta.error}</span>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </Field>
                </Col>
              </Row>
              )}
              <h4 className="title mb-3">Account Information</h4> 
              <Row>
                <Col md="6">
                  <Field name="first_name">
                    {({ input, meta }) => (
                      <FormGroup>
                        <Input
                          placeholder="First Name *"
                          type="text"
                          {...input}
                        />
                        {meta.touched && meta.error && (
                          <span className="form-error">{meta.error}</span>
                        )}
                      </FormGroup>
                    )}
                  </Field>
                </Col>
                <Col md="6">
                  <Field name="last_name">
                    {({ input, meta }) => (
                      <FormGroup>
                        <Input placeholder="Last Name *" type="text" {...input} />
                        {meta.touched && meta.error && (
                          <span className="form-error">{meta.error}</span>
                        )}
                      </FormGroup>
                    )}
                  </Field>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <Field name="external_id">
                    {({ input, meta }) => (
                      <FormGroup>
                        <Input placeholder="External ID" type="text" {...input} />
                        {meta.touched && meta.error && (
                          <span className="form-error">{meta.error}</span>
                        )}
                      </FormGroup>
                    )}
                  </Field>
                </Col>
                <Col md="6">
                  <Field name="email">
                    {({ input, meta }) => (
                      <FormGroup>
                        <Input
                          placeholder="Email Address *"
                          type="text"
                          {...input}
                        />
                        {meta.touched && meta.error && (
                          <span className="form-error">{meta.error}</span>
                        )}
                      </FormGroup>
                    )}
                  </Field>
                </Col>
              </Row>
              <Row>
              {awardLevelOptions && awardLevelOptions.length > 0 && (
                <Col md="6">
                  <Field name="award_level">
                    {({ input, meta }) => (
                      <FormGroup>
                        <Select
                          options={awardLevelOptions}
                          clearable={true}
                          className="react-select"
                          placeholder={"Award Level"}
                          classNamePrefix="react-select"
                          parse={(value) => {
                            // handleAwardLevelChange(value)
                            return value;
                          }}
                        />
                        {meta.touched && meta.error && (
                          <span className="form-error">{meta.error}</span>
                        )}
                      </FormGroup>
                    )}
                  </Field>
                </Col>
              )}
              {unitNumberOptions && unitNumberOptions.length > 0 && (
                <Col md="6">
                  <Field name="unit_number">
                    {({ input, meta }) => (
                      <FormGroup>
                        <Select
                          options={unitNumberOptions}
                          clearable={true}
                          className="react-select"
                          placeholder={"Unit Number"}
                          classNamePrefix="react-select"
                          parse={(value) => {
                            return value;
                          }}
                        />
                        {meta.touched && meta.error && (
                          <span className="form-error">{meta.error}</span>
                        )}
                      </FormGroup>
                    )}
                  </Field>
                </Col>
              )}
              </Row>
            
              <h4 className="title mb-3">Award Information</h4>
            
              <Row>
                <Col md="12">
                  <Field 
                    name="event_id"
                    parse={(value) => {
                      onChangeEvent(value);
                      return value;
                    }}
                    >
                    {({ input, meta }) => (
                      <FormGroup>
                        <Select
                          onChange={(selectedOption) => onChangeEvent(selectedOption)}
                          options={events}
                          clearable={false}
                          className="react-select"
                          placeholder={"Select an Event"}
                          classNamePrefix="react-select"
                          value={event ? labelizeNamedData([event]) : null}
                          // value={events.find(event => event.value === input.value) || null} // Set selected value
                          // onChange={(selectedOption) => {
                          //   input.onChange(selectedOption ? selectedOption.value : null); // Update form state
                          //   onChangeEvent(selectedOption); // Update local state
                          // }}
                          // onInputChange={form.mutators.onChangeEventValue}
                          {...input}
                        />
                        {meta.touched && meta.error && (
                          <span className="text-danger">{meta.error}</span>
                        )}
                      </FormGroup>
                    )}
                  </Field>
                  {loadingEvent && <span>{'Loading...'}</span>}
                </Col>
              </Row>
              {event && (
                <>
                  {!isBadgeAward( event.event_type_id ) && !isCustomAward( event.event_type_id ) && (
                  <>
                    <Row>
                      <Col md="6">
                        <Label>Maximum cash value</Label>
                      </Col>
                      <Col md="6">
                        <strong>{`$${event?.max_awardable_amount}`}</strong>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <Label>Custom cash value</Label>
                      </Col>
                      <Col md="6">
                        <Field name="override_cash_value">
                          {({ input, meta }) => (
                            <FormGroup>
                              <Input
                                placeholder={`Enter an amount < $${event.max_awardable_amount}`}
                                type="text"
                                onKeyUp={form.mutators.onChangeAwardValue}
                                {...input}
                              />
                              {meta.touched && meta.error && (
                                <span className="text-danger">
                                  {meta.error}
                                </span>
                              )}
                            </FormGroup>
                          )}
                        </Field>
                      </Col>
                    </Row>
                  </>
                  )}
                  <Row>
                    <Col md="12">
                      <Field name="message">
                        {({ input, meta }) => (
                          <FormGroup>
                            <Input
                              placeholder="Award Message"
                              type="textarea"
                              readOnly={!event.award_message_editable}
                              {...input}
                            />
                            {meta.touched && meta.error && (
                              <span className="text-danger">
                                {meta.error}
                              </span>
                            )}
                          </FormGroup>
                        )}
                      </Field>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <Field name="notes">
                        {({ input, meta }) => (
                          <FormGroup>
                            <Input
                              placeholder="Reward Notes(optional)"
                              type="textarea"
                              {...input}
                            />
                            {meta.touched && meta.error && (
                              <span className="text-danger">
                                {meta.error}
                              </span>
                            )}
                          </FormGroup>
                        )}
                      </Field>
                    </Col>
                  </Row>
                  {isCustomAward( event.event_type_id ) && (
                    <Row>
                      <Col md="12">
                        <Label className="mb-1">Restrictions</Label>
                        <Field name="restrictions">
                          {({ input, meta }) => (
                            <FormGroup>
                              <Input
                                placeholder="Restrictions "
                                type="textarea"
                                {...input}
                              />
                              {meta.touched && meta.error && (
                                <span className="text-danger">
                                  {meta.error}
                                </span>
                              )}
                            </FormGroup>
                          )}
                        </Field>
                      </Col>
                    </Row>
                  )}
                  <Row>
                    <Col md="6">
                      <Label>Documentation</Label>
                      <Field name="doc_file">
                        {({ input, meta }) => (
                            <FormGroup>
                              <Input
                                  placeholder="Browse"
                                  type="file"
                                  {...input}
                              />
                              {meta.touched && meta.error && (
                                  <span className="text-danger">
                                {meta.error}
                              </span>
                              )}
                            </FormGroup>
                        )}
                      </Field>
                    </Col>
                    <Col md="6">
                      <Label>Referrer</Label><br />
                      <Field name="referrer">
                        {({ input, meta }) => (
                            <FormGroup>
                              <Input
                                  placeholder="Referrer"
                                  type="text"
                                  {...input}
                              />
                              {meta.touched && meta.error && (
                                  <span className="text-danger">
                                {meta.error}
                              </span>
                              )}
                            </FormGroup>
                        )}
                      </Field>
                    </Col>
                  </Row>
                </>
              )}
              <div className="d-flex justify-content-center">
                <TemplateButton
                  type="submit"
                  text={loading ? inviting ? 'Inviting...' : 'Awarding...' : "Send Award"}
                  disabled={loading}
                  spinner={loading}
                />
              </div>
            </div>

          </form>
        )}
      </Form>

    </div>
  );
};

const validate = (values) => {
  let errors = {};
  if (!values.program) {
    errors.program = "Select a program";
  }
  if (!values.email) {
    errors.email = "Email is required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }
  if (!values.first_name) {
    errors.first_name = "First name is required";
  }
  if (!values.last_name) {
    errors.last_name = "Last name is required";
  }
  if (!values.event_id) {
    errors.event_id = "Select an event";
  }
  if (!values.override_cash_value) {
    errors.override_cash_value = "This field is required";
  }
  if (!values.message) {
    errors.message = "Message is required";
  }
  return errors;
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    rootProgram: state.rootProgram,
    organization: state.organization,
  };
};
export default connect(mapStateToProps)(AwardNewParticipant);
