import React, { useState, useMemo, useEffect } from "react";
import { usePagination, useTable, useRowSelect } from "react-table";
import { CircularProgress } from "@material-ui/core";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Table,
} from "reactstrap";
import {
  flashError,
  flashSuccess,
  useDispatch,
} from "@/shared/components/flash";
import { connect } from "react-redux";
import { TABLE_COLUMNS } from "./Columns";
import ReactTablePagination from "@/shared/components/table/components/ReactTablePagination";
import apiTableService from "@/services/apiTableService";
import IndeterminateCheckbox from "@/shared/components/form/IndeterminateCheckbox";
import axios from "axios";
import RewardIcon from "mdi-react/RedeemIcon";
import CancelIcon from "mdi-react/CancelCircleIcon";
import { TableSkeleton } from "@/shared/components/Skeletons";

const ACTIONS = [
  { label: "Claim Award", name: "claim_award" },
  // { label: "Reject Award", name: "reject_award" },
];
const queryPageSize = 10;

const SELECTION_COLUMN = {
  id: "selection",
  Header: ({ getToggleAllPageRowsSelectedProps }) => (
    <div>
      <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
    </div>
  ),
  Cell: ({ row }) => (
    <div>
      <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
    </div>
  ),
};

const RenderActions = ({ row, onClickActionCb }) => {
  let rows = [];
  if (row.original) {
    rows.push(row.original.id);
  }

  return (
    <div className="d-flex gap-2">
      <span
        className="action-item Reward hover-text cursor-pointer"
        onClick={() => onClickActionCb(rows, "claim_award")}
      >
        <RewardIcon color="#48CE76" size={30} />
        <div className={`tooltip-text`}>Claim</div>
      </span>
      <span
        className="action-item Reward hover-text cursor-pointer"
        onClick={() => onClickActionCb(rows, "claim_reject")}
      >
        <CancelIcon color="#E55E5E" size={30} />
        <div className={`tooltip-text`}>Deny</div>
      </span>
    </div>
  );
};

const ClaimAwardsTable = ({ auth, organization, program }) => {
  const [participantClaimAwards, setParticipantClaimAwards] = useState(null);
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);

  const dispatch = useDispatch();

  let final_columns = [
    ...[
      ...[SELECTION_COLUMN, ...TABLE_COLUMNS],
      {
        Header: "",
        accessor: "action",
        Footer: "Action",
        Cell: ({ row }) => (
          <RenderActions row={row} onClickActionCb={onClickAction} />
        ),
      },
    ],
  ];
  const columns = React.useMemo(
    () => final_columns,
    [organization, program, auth]
  );
  const onSelectAction = (name) => {
    const rows = selectedFlatRows.map((d) => d.original.id);
    onClickAction(rows, name);
  };

  const onClickAction = (rows, name) => {
    if (organization?.id && program?.id && auth?.id) {
      let formData = {};
      if (rows.length === 0) {
        flashError(dispatch, "Please select your award for claim!");
        return;
      }
      formData.claim_award_id = rows;
      const urlSuffix =
        name === "claim_award" ? "accept-claim-award" : "reject-claim-award";
      const msgSuccess =
        name === "claim_award"
          ? "You have claimed your award!"
          : "You have rejected your award!";

      setSubmitting(true);
      axios
        .put(
          `/organization/${organization.id}/program/${program.id}/user/${auth.id}/${urlSuffix}`,
          formData
        )
        .then((res) => {
          if (res.status === 200) {
            flashSuccess(dispatch, msgSuccess);
            window.location.reload();
            setSubmitting(false);
          }
        })
        .catch((error) => {
          flashError(dispatch, error.message);
          setSubmitting(false);
        });
    }
  };

  const tableInstance = useTable(
    {
      columns,
      data: useMemo(
        () => (participantClaimAwards ? participantClaimAwards?.results : []),
        [participantClaimAwards]
      ),
      initialState: {
        pageIndex: 0,
        pageSize: queryPageSize,
      },
      manualPagination: true,
      pageCount: Math.ceil(participantClaimAwards?.count / queryPageSize),
      autoResetSortBy: false,
      autoResetExpanded: false,
      autoResetPage: false,
    },
    usePagination,
    useRowSelect
  );
  const {
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    pageCount,
    pageOptions,
    gotoPage,
    previousPage,
    canPreviousPage,
    nextPage,
    canNextPage,
    setPageSize,
    selectedFlatRows,
    state: { pageIndex, pageSize },
  } = tableInstance;

  useEffect(() => {
    let mounted = true;
    setLoading(true);
    if (organization?.id && program?.id && auth?.id) {
      apiTableService
        .fetchData({
          url: `/organization/${organization.id}/program/${program.id}/user/${auth.id}/claim-award`,
          size: queryPageSize,
        })
        .then((items) => {
          if (mounted && items) {
            setParticipantClaimAwards(items);
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    return () => (mounted = false);
  }, [organization, program, auth, pageIndex]);

  const ActionsDropdown = () => {
    return (
      <UncontrolledDropdown>
        <DropdownToggle caret className="dropdowntoggle">
          Actions
        </DropdownToggle>
        <DropdownMenu>
          {ACTIONS.map((item, index) => {
            return (
              <DropdownItem
                key={`action-dropdown-item-${index}`}
                onClick={() => onSelectAction(item.name)}
              >
                {item.label}
              </DropdownItem>
            );
          })}
        </DropdownMenu>
        {submitting && <CircularProgress size="16px" />}
      </UncontrolledDropdown>
    );
  };

  if (loading && !participantClaimAwards) {
    return (
      <div style={{ padding: "20px 0px" }}>
        <TableSkeleton rows={3} columns={5} width={"100%"} height={15} />
      </div>
    );
  }

  const manualPageSize = [];
  return (
    <>
      <ActionsDropdown />
      <Table striped>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
      <div>
        {rows.length > 0 && (
          <>
            <ReactTablePagination
              page={page}
              gotoPage={gotoPage}
              previousPage={previousPage}
              nextPage={nextPage}
              canPreviousPage={canPreviousPage}
              canNextPage={canNextPage}
              pageOptions={pageOptions}
              pageSize={pageSize}
              pageIndex={pageIndex}
              pageCount={pageCount}
              setPageSize={setPageSize}
              manualPageSize={manualPageSize}
              dataLength={
                participantClaimAwards ? participantClaimAwards.count : 0
              }
            />
          </>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    program: state.program,
    organization: state.organization,
  };
};

export default connect(mapStateToProps)(ClaimAwardsTable);
