export const TABLE_COLUMNS = [
  {
    Header: "Program Name",
    accessor: "name",
  },
  {
    Header: "Requested by",
    accessor: "",
    Cell: ({ cell }) => {
      return (
        <span>
          {cell.row.original?.requestor_first_name}
          {cell.row.original?.requestor_last_name}
        </span>
      );
    },
  },
  {
    Header: "Recipient",
    accessor: "",
    Cell: ({ cell }) => {
      return (
        <span>
          {cell.row.original?.participant_first_name}
          {cell.row.original?.participant_last_name}
        </span>
      );
    },
  },
  {
    Header: "Approved by",
    accessor: "approved_by",
    Cell: ({ cell, values }) => {
      return (
        <span>
          {values?.requestor_first_name}
          {values?.requestor_last_name}
        </span>
      );
    },
  },
  {
    Header: "Event Name",
    accessor: "event_name",
  },
  {
    Header: "Amount",
    accessor: "amount",
    Cell: ({ value }) => {
      return <span>{value?.toFixed(2)}</span>;
    },
  },
  {
    Header: "Scheduled Date",
    accessor: "scheduled_date",
    Cell: ({ value }) => {
      let date = new Date(value).toLocaleDateString("en-US", {});
      return <span>{date}</span>;
    },
  },
  {
    Header: "Date Created",
    accessor: "created_at",
    Cell: ({ value }) => {
      let date = new Date(value).toLocaleDateString("en-US", {});
      return <span>{date}</span>;
    },
  },
  {
    Header: "Budgets Available",
    accessor: "budgets_available",
  },
];
