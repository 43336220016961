import React, { useEffect, useMemo, useRef, useState } from "react";
import { useExpanded, usePagination, useResizeColumns, useSortBy, useTable } from "react-table";
import { QueryClient, QueryClientProvider, useQuery } from 'react-query';
import ReactTablePagination from '@/shared/components/table/components/ReactTablePagination';
import { Col, Row } from 'reactstrap';
import { dateStrToYmd, getFirstDay } from '@/shared/helpers';
import { TABLE_COLUMNS } from "./columns";
import { connect } from "react-redux";
import {
    reducer,
    useEffectToDispatch,
    fetchApiData,
    fetchApiDataExport,
    initialState,
    Sorting
} from "@/shared/apiTableHelper";
import { clone } from 'lodash';
import AwardDetailFilter from "./AwardDetailFilter";
import { CSVLink } from "react-csv";

const queryClient = new QueryClient();

const DataTable = ({ organization, program, programs }) => {
    const [filter, setFilter] = useState({
        programs: programs,
        reportKey: 'sku_value',
        programId: program.id,
        from: dateStrToYmd(getFirstDay()),
        to: dateStrToYmd(new Date())
    });
    const [useFilter, setUseFilter] = useState(false);
    const [trigger, setTrigger] = useState(0);
    const [exportData, setExportData] = useState([]);
    const [exportHeaders, setExportHeaders] = useState([]);
    const [exportToCsv, setExportToCsv] = useState(false);
    const exportLink = useRef();

    const [{ queryPageIndex, queryPageSize, totalCount, queryPageFilter, queryPageSortBy, queryTrigger }, dispatch] =
        React.useReducer(reducer, initialState);

    const apiUrl = `/organization/${organization.id}/report/award-detail`;
    const { isLoading, error, data, isSuccess } = useQuery(
        ['', apiUrl, queryPageIndex, queryPageSize, queryPageFilter, queryPageSortBy, queryTrigger],
        () => fetchApiData({
            url: apiUrl,
            page: queryPageIndex,
            size: queryPageSize,
            filter,
            sortby: queryPageSortBy,
            trigger: queryTrigger
        }),
        {
            keepPreviousData: true,
            staleTime: Infinity,
        }
    );

    useEffect(() => {
        if (exportToCsv) {
            if (exportLink.current) {
                exportLink.current.link.click();
                setExportToCsv(false);
            }
        }
    }, [exportToCsv]);

    function objectToCSV(data) {
        const csvRows = data.map(row =>
            Object.values(row).map(value => JSON.stringify(value, replacer)).join(',')
        );

        return csvRows.join('\r\n');

        function replacer(key, value) {
            return value === null || value === undefined ? '' : value;
        }
    }

    const download = async (filterValues) => {
        let tmpFilter = clone(filterValues);
        tmpFilter.exportToCsv = 1;
        tmpFilter.server = 'program';

        const response = await fetchApiDataExport({
            url: apiUrl,
            filter: tmpFilter,
            sortby: queryPageSortBy,
            trigger: queryTrigger
        });

        if (response.results && Array.isArray(response.results.data)) {
            const csvData = objectToCSV(response.results.data);
            if (csvData) {
                setExportData(csvData);
                setExportHeaders(Object.keys(response.results.data[0]));
                setExportToCsv(true);
            } else {
                console.error('Failed to serialize data for CSV export');
            }
        } else {
            console.error('Data is not an array:', response.results);
        }
    };

    let columns = useMemo(() => TABLE_COLUMNS, []);

    const totalPageCount = Math.ceil(totalCount / queryPageSize);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        footerGroups,
        rows,
        prepareRow,
        page,
        pageCount,
        pageOptions,
        gotoPage,
        previousPage,
        canPreviousPage,
        nextPage,
        canNextPage,
        setPageSize,
        state: { pageIndex, pageSize, sortBy }
    } = useTable({
            columns: columns,
            data: data ? Object.values(data.results) : [],
            initialState: {
                pageIndex: queryPageIndex,
                pageSize: queryPageSize,
                sortBy: queryPageSortBy,
            },
            manualPagination: true, // Tell the usePagination
            pageCount: data ? totalPageCount : null,
            autoResetSortBy: false,
            autoResetExpanded: false,
            autoResetPage: false,
            disableResizing: true,
            autoResetHiddenColumns: false,
            striped: true
        },
        useSortBy,
        useExpanded,
        usePagination,
        useResizeColumns,
    );

    useEffectToDispatch(dispatch, { pageIndex, pageSize, gotoPage, sortBy, filter, data, useFilter, trigger });

    if (isLoading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p>Error: {JSON.stringify(error)}</p>;
    }

    if (isSuccess) {
        return (
            <>
                <div className='table react-table report-table'>
                    <div className="action-panel">
                        <Row className="mx-0">
                            <Col>
                                <AwardDetailFilter
                                    filter={filter}
                                    setFilter={setFilter}
                                    useFilter={useFilter}
                                    setUseFilter={setUseFilter}
                                    exportData={exportData}
                                    exportLink={exportLink}
                                    exportHeaders={exportHeaders}
                                    download={download}
                                />
                            </Col>
                        </Row>
                    </div>
                    {
                        isLoading && <p>Loading...</p>
                    }
                    {
                        isSuccess &&
                        <table {...getTableProps()} className="table table-striped report-table">
                            <thead>
                            {headerGroups.map((headerGroup) => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map(column => (
                                        <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                            {column.render('Header')}
                                            {column.isSorted ? <Sorting column={column} /> : ''}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                            </thead>
                            <tbody className="table table--bordered" {...getTableBodyProps()}>
                            {page.map(row => {
                                prepareRow(row);
                                const subCount = (row.id.match(/\./g) || []).length;
                                const subRows = row.subRows;

                                const countSubRows = subRows ? subRows.length : 0;
                                const rowSpan = countSubRows ? countSubRows + 1 : 1;
                                return (
                                    <React.Fragment key={row.id}>
                                        <tr {...row.getRowProps()}>
                                            {
                                                row.cells.map(cell => {
                                                    const skip = cell.value === 'skip_td';
                                                    if (skip) return null;
                                                    const paddingLeft = subCount * 20;
                                                    return <td {...cell.getCellProps()} rowSpan={rowSpan} key={cell.column.id + row.id}>
                                                        <span style={cell.column.Header === '#' ? { paddingLeft: `${paddingLeft}px` } : null}>{cell.render('Cell')}</span>
                                                    </td>;
                                                })
                                            }
                                        </tr>
                                        {countSubRows > 0 && subRows.map(subRow => {
                                            prepareRow(subRow);
                                            return (
                                                <tr {...subRow.getRowProps()} key={subRow.id}>
                                                    {
                                                        subRow.cells.map(subCell => {
                                                            const skip = subCell.value === 'skip_td';
                                                            if (skip) return null;
                                                            return <td {...subCell.getCellProps()} key={subCell.column.id + subRow.id}>
                                                                <span>{subCell.render('Cell')}</span>
                                                            </td>;
                                                        })
                                                    }
                                                </tr>
                                            );
                                        })}
                                    </React.Fragment>
                                );
                            })}
                            </tbody>
                            <tfoot>
                            {footerGroups.map((footerGroup) => (
                                <tr {...footerGroup.getFooterGroupProps()}>
                                    {footerGroup.headers.map(column => (
                                        <th {...column.getFooterProps()}>{column.render('Footer')}</th>
                                    ))}
                                </tr>
                            ))}
                            </tfoot>
                        </table>
                    }

                    {(rows.length > 0) && (
                        <>
                            <ReactTablePagination
                                page={page}
                                gotoPage={gotoPage}
                                previousPage={previousPage}
                                nextPage={nextPage}
                                canPreviousPage={canPreviousPage}
                                canNextPage={canNextPage}
                                pageOptions={pageOptions}
                                pageSize={pageSize}
                                pageIndex={pageIndex}
                                pageCount={pageCount}
                                setPageSize={setPageSize}
                                dataLength={totalCount}
                            />
                        </>
                    )}
                </div>
            </>
        );
    }
}

const TableWrapper = ({ organization, program, programs }) => {
    if (!organization || !program) return 'Loading...';
    return (
        <QueryClientProvider client={queryClient}>
            <DataTable organization={organization} program={program} programs={programs} />
        </QueryClientProvider>
    );
}

const mapStateToProps = (state) => {
    return {
        program: state.program,
        organization: state.organization,
    };
};
export default connect(mapStateToProps)(TableWrapper);
