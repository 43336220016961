import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Navbar, NavbarBrand, Spinner } from "reactstrap";
import { useLocation } from "react-router-dom";
import ClaimAwardsPopup from "./components/ClaimAwardsPopup";
import axios from "axios";

const ClaimAwardsAcceptOrReject = ({ template }) => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const urlPrefix =
    location.pathname == "/accept-claim-award/"
      ? "/accept-claim-award/"
      : "/reject-claim-award/";
  const params = searchParams.get("_c");

  const closeModal = () => setOpen(false);

  useEffect(() => {
    if (params) {
      setLoading(true);
      let apiUrl = urlPrefix + "?_c=" + params;
      let msg =
        location.pathname == "/accept-claim-award/"
          ? "Award Accepted successfully!"
          : "Award Rejected successfully!";
      axios
        .post(apiUrl)
        .then((res) => {
          if (res.status === 200) {
            setOpen(true);
            setLoading(false);
            setMessage(msg);
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err.message);
        });
    }
  }, [params]);

  const Brand = `${process.env.REACT_APP_API_STORAGE_URL}/${template.small_logo}`;

  if (!template || loading)
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{
          height: "100vh",
          width: "100vw",
        }}
      >
        <Spinner
          color={
            location.pathname == "/accept-claim-award/" ? "success" : "danger"
          }
          type="grow"
        >
          Loading...
        </Spinner>
      </div>
    );
  return (
    <div>
      <div className="topbar home">
        <div className="topbar__wrapper">
          <Navbar color="" expand="md" fixed="" light>
            <div className="navbar-top_row">
              <div className="navbar-brand-logowrap">
                <NavbarBrand href="/">
                  <img src={Brand} alt="Brand" />
                </NavbarBrand>
              </div>
            </div>
          </Navbar>
        </div>
      </div>
      {open && (
        <ClaimAwardsPopup
          message={message}
          open={open}
          setOpen={setOpen}
          closeModal={closeModal}
          location={location}
        />
      )}
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    template: state.domain?.program?.template,
  };
};
export default connect(mapStateToProps)(ClaimAwardsAcceptOrReject);
