import { createFinalFormValidation } from "@lemoncode/fonk-final-form";
import { Validators } from '@lemoncode/fonk';
import { isNumber } from '@lemoncode/fonk-is-number-validator';
import { isTrue } from '@lemoncode/fonk-is-true-validator';
import {isBadgeAward, isCustomAward} from "@/shared/helpers";

isTrue.setErrorMessage("This field is required");

Validators.required.setErrorMessage("This field is required");

export const overrideCashValueValidator = args => {
  const { values } = args;
  if( isBadgeAward(values.event_type_id) || isCustomAward(values.event_type_id) ){
      return {
          succeeded: true,
          type: 'override_cash_value_validator',
          message: ''
      }
  }else{
      return Validators.required.validator(args);
  }
}

const validationSchema = {
    field: {
        program: [Validators.required.validator],
        first_name: [Validators.required.validator],
        last_name: [Validators.required.validator],
        email: [Validators.required.validator, Validators.email.validator],
        event_id: [Validators.required.validator],
        override_cash_value: [overrideCashValueValidator, isNumber.validator],
        message: [Validators.required.validator],
    }
}
const formValidation = createFinalFormValidation(validationSchema);
export default formValidation;
