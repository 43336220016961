import {getFormattedDate} from '@/shared/helpers'
export const TABLE_COLUMNS = [
  {
    Header: "Program",
    accessor: "program_name",
  },
  {
    Header: "Event",
    accessor: "event_name",
  },
  {
    Header: "Points",
    accessor: "claim_points",
  },
  {
    Header: "Issued On",
    accessor: "date_issued",
    Cell: ({ row, value }) => {
      return getFormattedDate(new Date(value));
    },
  },
  {
    Header: "Expires On",
    accessor: "date_expires",
    Cell: ({ row, value }) => {
      return getFormattedDate(new Date(value));
    },
  },
  {
    Header: "Status",
    accessor: "status",
    Cell: ({ row, value }) => {
      return parseStatus(row.original.status);
    },
  },
  {
    Header: "Schedule",
    accessor: "scheduled_date",
    Cell: ({ row, value }) => {
      return value ? getFormattedDate(new Date(value)) : '--NA--';
    },
  },
];

const parseStatus = (status) => {
  if(status === 0) return "Pending"
  if(status === 1) return "Claimed"
  if(status === 2) return "Rejected"
}
