import React from "react";
import { Row, Col, Container } from "reactstrap";
import Sidebar from "@/containers/Layout/sidebar";
import ClaimAwardsTable from "./components/ClaimAwardsTable";
import { useTranslation } from "react-i18next";

const Index = () => {
  const { t } = useTranslation();
  return (
    <div className="m-4 mt-5">
      <Row>
        <Col md={4}>
          <Sidebar />
        </Col>
        <Col md={7} className="peer-peer">
          <div className="mb-5">
            <h4>{t("claim_awards")}</h4>
            <p className="text-justify">{t("claim_award_desc")}</p>
          </div>
          <ClaimAwardsTable />
        </Col>
      </Row>
    </div>
  );
};

export default Index;
