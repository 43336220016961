// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.not-found-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  max-width: 400px;
  margin: 100px auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.not-found-container .not-found-heading {
  font-size: 80px;
  margin-bottom: 10px;
  color: #ff6f6f;
}
.not-found-container .not-found-message {
  font-size: 16px;
  margin-bottom: 10px;
}
.not-found-container .home-link {
  display: inline-block;
  padding: 10px 20px;
  background-color: #ff6f6f;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s;
}
.not-found-container .home-link:hover {
  background-color: #ff5252;
}`, "",{"version":3,"sources":["webpack://./src/scss/component/pageNotFound.scss"],"names":[],"mappings":"AACA;EACI,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,kBAAA;EACA,gBAAA;EACA,kBAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,wCAAA;AAAJ;AAEI;EACE,eAAA;EACA,mBAAA;EACA,cAAA;AAAN;AAGI;EACE,eAAA;EACA,mBAAA;AADN;AAII;EACE,qBAAA;EACA,kBAAA;EACA,yBAAA;EACA,WAAA;EACA,qBAAA;EACA,kBAAA;EACA,iCAAA;AAFN;AAIM;EACE,yBAAA;AAFR","sourcesContent":["// src/components/NotFoundPage.scss\n.not-found-container {\n    display: flex;\n    flex-direction: column;\n    justify-content: center; // Center vertically\n    align-items: center; // Center horizontally\n    text-align: center;\n    max-width: 400px;\n    margin: 100px auto;\n    padding: 20px;\n    background-color: #fff;\n    border-radius: 10px;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n  \n    .not-found-heading {\n      font-size: 80px;\n      margin-bottom: 10px;\n      color: #ff6f6f;\n    }\n  \n    .not-found-message {\n      font-size: 16px;\n      margin-bottom: 10px;\n    }\n  \n    .home-link {\n      display: inline-block;\n      padding: 10px 20px;\n      background-color: #ff6f6f;\n      color: #fff;\n      text-decoration: none;\n      border-radius: 5px;\n      transition: background-color 0.3s;\n  \n      &:hover {\n        background-color: #ff5252;\n      }\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
