import React from "react";
import { connect } from "react-redux";
import { Badge } from "@material-ui/core";

const AwardClaimNotification = ({ claimAwardCounts, children }) => {
  return (
    <>
      {claimAwardCounts > 0 && (
        <Badge badgeContent={claimAwardCounts} color="primary" overlap="rectangular">
          <span>{children}</span>
        </Badge>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    program: state.program,
    organization: state.organization,
  };
};

export default connect(mapStateToProps)(AwardClaimNotification);
