import { toPoints } from "@/shared/helpers";

export const TABLE_COLUMNS = [
  {
    Header: "Date Created",
    accessor: "created_at",
    Cell: ({ row, value }) => {
      return new Date(row.original.created_at)?.toLocaleDateString("en-us");
    },
  },
  {
    Header: "Program Name",
    accessor: "program_name",
  },
  {
    Header: "Event Name",
    accessor: "event_name",
  },
  {
    Header: "To",
    accessor: "recipient_full_name",
  },
  {
    Header: "Email Address",
    accessor: "recipient_email",
  },
  {
    Header: "Submitted By",
    accessor: "awarder_full_name",
  },
  {
    Header: "Value",
    accessor: "award_points",
  },
  {
    Header: "Dollar Value",
    accessor: "award_amount",
    Cell: ({ row, value }) => {
      return (
        <>
          {row.original.award_amount ? (
            <span>${row.original.award_amount?.toFixed(2)}</span>
          ) : (
            ""
          )}
        </>
      );
    },
  },
  {
    Header: "Scheduled Date",
    accessor: "scheduled_date",
    Cell: ({ row, value }) => {
      return (
        <span>
          {row.original.scheduled_date &&
            new Date(row.original.scheduled_date)?.toLocaleDateString("en-us")}
        </span>
      );
    },
  },
  {
    Header: "Status",
    accessor: "scheduled_status",
  },
];
