export const USERS_COLUMNS = [
    {
        Header: "name",
        accessor: "name",
    }
    
]

export const USERS_DATA =[
    {
        name: "Bobrowski, Robert",
        email:"r.bobrowski@temp-neteos.pl"
    },
    {
        name: "Bobrowski, Robert",
        email:"r.bobrowski@temp-neteos.pl"
    },
    {
        name: "Bobrowski, Robert",
        email:"r.bobrowski@temp-neteos.pl"
    },
    {
        name: "Bobrowski, Robert",
        email:"r.bobrowski@temp-neteos.pl"
    },
    {
        name: "Bobrowski, Robert",
        email:"r.bobrowski@temp-neteos.pl"
    },
    {
        name: "Bobrowski, Robert",
        email:"r.bobrowski@temp-neteos.pl"
    },
    {
        name: "Bobrowski, Robert",
        email:"r.bobrowski@temp-neteos.pl"
    },
    {
        name: "Bobrowski, Robert",
        email:"r.bobrowski@temp-neteos.pl"
    },
    {
        name: "Bobrowski, Robert",
        email:"r.bobrowski@temp-neteos.pl"
    }
       
]