// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tab-nav {
  height: 100px;
  width: 100%;
  position: relative;
  border-top: solid 1px black;
  padding: 10px;
  margin: 10px;
  cursor: pointer;
}
.tab-nav:after {
  content: "";
  position: absolute;
  right: 0;
  z-index: 100;
  top: 0;
  width: 1px;
  height: 50%; /* or 100px */
  background: black;
}
.tab-nav:before {
  content: "";
  position: absolute;
  left: 0;
  z-index: 100;
  top: 0;
  width: 1px;
  height: 50%; /* or 100px */
  background: black;
}
.tab-nav .icon {
  margin-top: 10px;
  text-align: center;
}
.tab-nav .icon .redeem-icon {
  color: #DA282D;
}
.tab-nav .icon .gift-icon {
  color: #FBAE42;
}
.tab-nav .icon .survey-icon {
  color: #8BC53E;
}
.tab-nav .icon .newsletter-icon {
  color: #BFBFBF;
}
.tab-nav .icon .submit-icon {
  color: #92268E;
}
.tab-nav .icon.active {
  color: #FBAE42;
}
.tab-nav .icon.active + div {
  color: #FBAE42;
}
.tab-nav .title {
  margin-top: 20px;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/shared/components/tabNavs/components/style.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,WAAA;EACA,kBAAA;EACA,2BAAA;EACA,aAAA;EACA,YAAA;EACA,eAAA;AACJ;AAAI;EACI,WAAA;EACA,kBAAA;EACA,QAAA;EACA,YAAA;EACA,MAAA;EACA,UAAA;EACA,WAAA,EAAA,aAAA;EACA,iBAAA;AAER;AAAI;EACI,WAAA;EACA,kBAAA;EACA,OAAA;EACA,YAAA;EACA,MAAA;EACA,UAAA;EACA,WAAA,EAAA,aAAA;EACA,iBAAA;AAER;AAAI;EACI,gBAAA;EACA,kBAAA;AAER;AADQ;EACI,cAAA;AAGZ;AADQ;EACI,cAAA;AAGZ;AADQ;EACI,cAAA;AAGZ;AADQ;EACI,cAAA;AAGZ;AADQ;EACI,cAAA;AAGZ;AADQ;EACI,cAAA;AAGZ;AAFY;EACI,cAAA;AAIhB;AACI;EACI,gBAAA;EACA,kBAAA;AACR","sourcesContent":[".tab-nav {\n    height: 100px;\n    width: 100%;\n    position: relative;\n    border-top: solid 1px black;    \n    padding: 10px;\n    margin: 10px;\n    cursor: pointer;\n    &:after {\n        content : \"\";\n        position: absolute;\n        right    : 0;\n        z-index: 100;\n        top  : 0;\n        width  : 1px;\n        height   : 50%;  /* or 100px */\n        background: black;\n    }\n    &:before {\n        content : \"\";\n        position: absolute;\n        left    : 0;\n        z-index: 100;\n        top  : 0;\n        width  : 1px;\n        height   : 50%;  /* or 100px */\n        background: black;\n    }\n    .icon{\n        margin-top: 10px;\n        text-align: center;\n        .redeem-icon{\n            color: #DA282D;\n        }\n        .gift-icon{\n            color: #FBAE42;\n        }\n        .survey-icon{\n            color: #8BC53E;\n        }\n        .newsletter-icon{\n            color: #BFBFBF;\n        }\n        .submit-icon{\n            color: #92268E;\n        }\n        &.active{\n            color: #FBAE42;\n            + div{\n                color: #FBAE42;\n            }\n        }\n        \n    }\n    .title{\n        margin-top: 20px;\n        text-align: center;\n    }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
