import { toPoints } from "@/shared/helpers";

export const TABLE_COLUMNS = [
  {
    Header: "Program Id",
    accessor: "program_id",
  },
  {
    Header: "Program Name",
    accessor: "program_name",
  },
  {
    Header: "First Name",
    accessor: "recipient_first_name",
  },
  {
    Header: "Last Name",
    accessor: "recipient_last_name",
  },
  {
    Header: "Email",
    accessor: "recipient_email",
  },
  {
    Header: "Event",
    accessor: "event_name",
  },
  {
    Header: "Points Awarded",
    accessor: "amount",
    Cell: ({ row, value }) => {
      return toPoints(row.original.factor_valuation * value);
    },
  },
  {
    Header: "Created",
    accessor: "created_at",
    Cell: ({ row, value }) => {
      return (
        <span>
          {new Date(row.original.created_at).toLocaleDateString("en-us")}
        </span>
      );
    },
  },
  {
    Header: "Expires",
    accessor: "expires_at",
    Cell: ({ row, value }) => {
      return new Date(row.original.expires_at).toLocaleString("en-us");
    },
  },
  {
    Header: "Status",
    accessor: "claim_award_status",
    textAlign: "center"
  },
];
