// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.authresp-error .modal-title{color:red;}
.authresp-expired .modal-title{color:red;}
.authresp-already .modal-title{color:rgb(102, 0, 255);}
.authresp-processed .modal-title{color:green;}
.modal-authresp .p, .modal-authresp .content{color:black}
.modal-authresp a{color:black;text-decoration: underline !important;}`, "",{"version":3,"sources":["webpack://./src/containers/Manager/ManageAccount/components/style.css"],"names":[],"mappings":"AAAA,6BAA6B,SAAS,CAAC;AACvC,+BAA+B,SAAS,CAAC;AACzC,+BAA+B,sBAAsB,CAAC;AACtD,iCAAiC,WAAW,CAAC;AAC7C,6CAA6C,WAAW;AACxD,kBAAkB,WAAW,CAAC,qCAAqC,CAAC","sourcesContent":[".authresp-error .modal-title{color:red;}\n.authresp-expired .modal-title{color:red;}\n.authresp-already .modal-title{color:rgb(102, 0, 255);}\n.authresp-processed .modal-title{color:green;}\n.modal-authresp .p, .modal-authresp .content{color:black}\n.modal-authresp a{color:black;text-decoration: underline !important;}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
