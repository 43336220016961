import React, {useEffect, useState } from 'react'
import { Modal, Col, Row, Label, FormGroup, Input} from 'reactstrap'
import { Field, Form } from 'react-final-form'
import CloseIcon from 'mdi-react/CloseIcon'
import {flashDispatch, flashMessage } from '@/shared/helpers'
import ApiErrorMessage from "@/shared/components/flash/ApiErrorMessage"
import axios from 'axios'
import TemplateButton from "@/shared/components/TemplateButton"
import { ErrorMessage } from '@/shared/components/ErrorMsg'
import { useTranslation } from 'react-i18next'
import { sendFlashMessage } from '@/redux/actions/flashActions'
import Select from 'react-select'

const EditParticipantModal = ({isOpen, setOpen, toggle, participants, program, organization, setRefreshUsers}) => {
  const { t } = useTranslation();
  const dispatch = flashDispatch()
  const [saving, setSaving] = useState(false);
  const [awardLevel, setAwardLevel] = useState({})
  const [awardLevelOptions, setAwardLevelOptions] = useState([])
  const [user, setUser] = useState({});
  
  const validate = (values) => {
    let errors = {};
    if (!values.first_name) {
      errors.first_name = t("please_enter_first_name");
    }
    if (!values.last_name) {
      errors.last_name = t("please_enter_last_name");
    }
    if (!values.email) {
      errors.email = t("email_is_required");
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = t("invalid_email_address");
    }
    return errors;
  };

  const onSelectLevel = (selectedOption) => {
    setAwardLevel( selectedOption )
  };

  const getDataItems = async () => {
    const response = await axios.get(
        `/organization/${program.organization_id}/program/${program.id}/program-award-levels`,
    ); 
    let options = []
    if(response.data?.length){
        response.data.forEach(item => {
            options.push({label: item.name, value: item.id})
            if(item.name == user.award_level){
                setAwardLevel({label: item.name, value: item.id})
            }
        });
        setAwardLevelOptions(options)
    }
  };

  useEffect(() => {
    setUser(participants[0])
}, [participants]);

  useEffect(() => {
      getDataItems()
  }, [program, user]);

  const onSubmit = (values) => {
    if(awardLevel){
      values.award_level = awardLevel.value
    }
    setSaving(true)
    axios
      .put(`/organization/${organization.id}/program/${program.id}/user/${user.id}`, values)
      .then((res) => {
        if (res.status === 200) {
          dispatch(
            flashMessage(
              "Participant Information Updated successfully!"
            )
          );
          setSaving(false)
          setRefreshUsers(true);
        }
      })
      .catch((error) => {
        // return false;
        if (error?.response?.data) {
          dispatch(
            sendFlashMessage(
              <ApiErrorMessage errors={error.response.data} />,
              "alert-danger"
            )
          );
          setSaving(false);
        }
      });
  };

  const initialValues = {
    first_name: user.first_name,
    last_name: user.last_name,
    email: user.email,
    external_id: user.external_id
  };

  return (
    <Modal className={`program-settings modal-lg`} isOpen={isOpen} toggle={() => setOpen(true)}>
          <div className='close cursor-pointer'>
            <CloseIcon onClick={toggle} size={30}/>
          </div>
          <div className="container">
            <div className='title mt-3'>
              <h3>Edit Information</h3>
            </div>
            {saving && 'Saving Account Information, please wait...'}
            <Form
              onSubmit={onSubmit}
              initialValues={initialValues}
              validate={validate}
            >
            {({ handleSubmit, form, submitting, pristine, values }) => (
              <form
                className="form d-flex flex-column justify-content-evenly p-2"
                onSubmit={handleSubmit}
              >
                <Row>
                  <Col md="12">
                    <Field name="first_name">
                      {({ input, meta }) => (
                        <>
                          <FormGroup className="d-flex justify-content-between">
                            <Label className="w-50">* {t("first_name")}:</Label>
                            <div className="w-100">
                              <Input placeholder="" type="text" {...input} />
                              {meta.touched && meta.error && (
                                <ErrorMessage msg={meta.error} />
                              )}
                            </div>
                          </FormGroup>
                        </>
                      )}
                    </Field>
                  </Col>
                </Row>

                <Row>
                  <Col md="12">
                    <Field name="last_name">
                      {({ input, meta }) => (
                        <FormGroup className="d-flex justify-content-between">
                          <Label className="w-50">* {t("last_name")}:</Label>
                          <div className="w-100">
                            <Input placeholder="" type="text" {...input} />
                            {meta.touched && meta.error && (
                              <ErrorMessage msg={meta.error} />
                            )}
                          </div>
                        </FormGroup>
                      )}
                    </Field>
                  </Col>
                </Row>

                <Row>
                  <Col md="12">
                    <Field name="external_id">
                      {({ input, meta }) => (
                        <FormGroup className="d-flex justify-content-between">
                          <Label className="w-50">External ID:</Label>
                          <div className="w-100">
                            <Input placeholder="" type="text" {...input} />
                            {meta.touched && meta.error && (
                              <ErrorMessage msg={meta.error} />
                            )}
                          </div>
                        </FormGroup>
                      )}
                    </Field>
                  </Col>
                </Row>

                <Row>
                  <Col md="12">
                    <Field name="email">
                      {({ input, meta }) => (
                        <FormGroup className="d-flex justify-content-between">
                          <Label className="w-50">
                            * {t("email_address")}:
                          </Label>
                          <div className="w-100">
                            <Input placeholder="" type="email" {...input} />
                            {meta.touched && meta.error && (
                              <ErrorMessage msg={meta.error} />
                            )}
                          </div>
                        </FormGroup>
                      )}
                    </Field>
                  </Col>
                </Row>

                <Row>
                  <Col md="12">
                  <FormGroup className="d-flex justify-content-between">
                        <Label className="w-50"> Award level </Label>
                        <div className="w-100">
                            <Select
                                options={awardLevelOptions}
                                clearable={false}
                                className="react-select"
                                placeholder={' --- '}
                                classNamePrefix="react-select"
                                value={awardLevel}
                                onChange={onSelectLevel}
                            />
                        </div>
                      </FormGroup>
                  </Col>
                </Row>

                <div className="d-flex justify-content-end">
                  <TemplateButton
                    type="submit"
                    disabled={saving}
                    text={"Save Participant"}
                  />
                </div>
              </form>
            )}
            </Form>
          </div>
    </Modal>
)}

const validate = () => {
  let errors = {
  }
  return errors
}

export default EditParticipantModal;
