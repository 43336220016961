import React from 'react';
import { Row} from 'reactstrap';
import ClaimAwardSummaryCard from './components/ClaimAwardSummaryIndex.jsx';

const ClaimAwardSummary = () => {
  return (
      <Row>
        <ClaimAwardSummaryCard/>
      </Row>
  )
}

export default ClaimAwardSummary;
