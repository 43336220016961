import React from "react";
import TemplateButton from "@/shared/components/TemplateButton";
import { Button, Modal, ModalBody } from "reactstrap";
import CloseIcon from "mdi-react/CloseIcon";
import SadIcon from "mdi-react/EmoticonOutlineIcon";
import { useNavigate } from "react-router-dom";

const ClaimAwardsPopup = ({ message, open, setOpen, closeModal, location }) => {
  const navigate = useNavigate();
  const onClickOk = () => {
    closeModal();
    navigate("/");
  };

  return (
    <>
      {" "}
      <Modal isOpen={open} toggle={() => setOpen(true)}>
        <div className="close cursor-pointer">
          <CloseIcon onClick={onClickOk} size={30} />
        </div>{" "}
        <div className="d-flex mt-4 justify-content-center ">
          <h2 className="title text-center">Claim Awards</h2>
        </div>
        <ModalBody>
          <p
            style={{ fontSize: "22px" }}
            className={
              location.pathname == "/accept-claim-award/"
                ? "text-success d-flex justify-content-center mb-4"
                : "text-danger d-flex justify-content-center mb-4"
            }
          >
            {message} <SadIcon size={30} color="#eb8634" />
          </p>
          <div className="d-flex justify-content-center">
            <Button
              type="button"
              onClick={onClickOk}
              color="primary"
              className="btn btn-primary px-4 py-2"
            >
              Ok
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default ClaimAwardsPopup;
