// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.react-switch-checkbox {
  height: 0;
  width: 0;
  visibility: hidden;
}

.react-switch-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 45px;
  height: 25px;
  background: rgba(252, 176, 64, 0.2);
  border-radius: 100px;
  position: relative;
  transition: background-color 0.2s;
}

.react-switch-label .react-switch-button {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  transition: 0.2s;
  background: #fff;
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}

.react-switch-checkbox:checked + .react-switch-label .react-switch-button {
  left: calc(100% - 2px);
  transform: translateX(-100%);
}

.react-switch-label:active .react-switch-button {
  width: 60px;
}`, "",{"version":3,"sources":["webpack://./src/shared/components/form/Switch.scss"],"names":[],"mappings":"AAAA;EACI,SAAA;EACA,QAAA;EACA,kBAAA;AACJ;;AAEE;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,eAAA;EACA,WAAA;EACA,YAAA;EACA,mCAAA;EACA,oBAAA;EACA,kBAAA;EACA,iCAAA;AACJ;;AAEE;EACE,WAAA;EACA,kBAAA;EACA,QAAA;EACA,SAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,gBAAA;EACA,gBAAA;EACA,4CAAA;AACJ;;AAEE;EACE,sBAAA;EACA,4BAAA;AACJ;;AAEE;EACE,WAAA;AACJ","sourcesContent":[".react-switch-checkbox {\n    height: 0;\n    width: 0;\n    visibility: hidden;\n  }\n  \n  .react-switch-label {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    cursor: pointer;\n    width: 45px;\n    height: 25px;\n    background: rgba($color: #FCB040, $alpha: 0.2);\n    border-radius: 100px;\n    position: relative;\n    transition: background-color .2s;\n  }\n  \n  .react-switch-label .react-switch-button {\n    content: '';\n    position: absolute;\n    top: 2px;\n    left: 2px;\n    width: 20px;\n    height: 20px;\n    border-radius: 50%;\n    transition: 0.2s;\n    background: #fff;\n    box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);\n  }\n  \n  .react-switch-checkbox:checked + .react-switch-label .react-switch-button {\n    left: calc(100% - 2px);\n    transform: translateX(-100%);\n  }\n  \n  .react-switch-label:active .react-switch-button {\n    width: 60px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
