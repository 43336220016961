import React from 'react';
import { Row} from 'reactstrap';
import CascadingApprovalSummaryCard from './components/CascadingApprovalSummaryIndex.jsx';

const CascadingApprovalSummary = () => {
  return (
      <Row>
        <CascadingApprovalSummaryCard/>
      </Row>
  )
}

export default CascadingApprovalSummary;
